.react-tooltip {
  @apply w-[83vw] max-w-xs filter drop-shadow-tooltip text-base p-0 bg-transparent leading-tight hidden !important;
}
.react-tooltip.show {
  @apply opacity-100 inline-block !important;
}

.react-tooltip::before,
.react-tooltip::after {
  @apply hidden sm:block;
}

/* Using mas-width since we only want to override these values for xs and sm */
@media (max-width: 640px) {
  #tooltip.react-tooltip.show {
    @apply left-0 right-0 !important;
  }
}

.tooltip-content > *{
 @apply mt-2 text-tooltip-font text-sm font-normal; 
}